import axios from 'axios';
import React, { useState } from 'react';
import { api } from '../services/api';

interface WaitingListProps {
	productName: string;
	title: string;
	subtitle: string;
	cta: Array<{
		text: string;
		link: string;
		style: string;
	}>;
	onSubmit: () => void;
}

const WaitingList: React.FC<WaitingListProps> = ({ productName, title, subtitle, cta, onSubmit }) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [motivations, setMotivations] = useState('');
	const [interviewParticipant, setInterviewParticipant] = useState(false);
	const [result, setResult] = useState<{ status: string; position: number; product: string } | null>(null);
	const [error, setError] = useState<string | null>(null);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setError(null);
		const data = {
			name,
			email,
			motivations,
			product_name: productName,
			interview_participant: interviewParticipant,
		};
		console.log('Sending data:', data);
		try {
			const response = await api.joinWaitingList(data);
			setResult(response);
		} catch (err: unknown) {
			console.error('Error joining waiting list:', err);
			if (axios.isAxiosError(err) && err.response) {
				setError(`Error: ${err.response.data.error || 'Unknown error occurred'}`);
			} else {
				setError('An unexpected error occurred. Please try again.');
			}
		}
	};

	return (
		<section className="bg-white py-24 sm:py-32" id="waiting_list">
			<div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
				<div className="mx-auto max-w-4xl">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{title}</h2>
					<p className="mt-2 text-lg leading-8 text-gray-600 text-center">{subtitle}</p>
					{result ? (
						<p className="mt-6 text-lg leading-8 text-gray-600 text-center">{result.status === 'added_to_waiting_list' ? `You've been added to the waiting list for ${result.product}! Your position is ${result.position}.` : `You're already on the waiting list for ${result.product}. Your position is ${result.position}.`}</p>
					) : (
						<form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
							<div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
								<div>
									<label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
										Name
									</label>
									<input type="text" name="name" id="name" autoComplete="name" value={name} onChange={(e) => setName(e.target.value)} className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required />
								</div>
								<div>
									<label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
										Email
									</label>
									<input type="email" name="email" id="email" autoComplete="email" value={email} onChange={(e) => setEmail(e.target.value)} className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required />
								</div>
								<div className="sm:col-span-2">
									<label htmlFor="motivations" className="block text-sm font-semibold leading-6 text-gray-900">
										What are your motivations for joining?
									</label>
									<textarea name="motivations" id="motivations" rows={4} value={motivations} onChange={(e) => setMotivations(e.target.value)} className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
								</div>
								<div className="sm:col-span-2">
									<div className="flex items-center">
										<input type="checkbox" name="interview-participant" id="interview-participant" checked={interviewParticipant} onChange={(e) => setInterviewParticipant(e.target.checked)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
										<label htmlFor="interview-participant" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
											I'm willing to participate in interviews
										</label>
									</div>
								</div>
							</div>
							<div className="mt-10">
								<button type="submit" className="block w-full rounded-full bg-blue-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
									Join Waiting List
								</button>
							</div>
						</form>
					)}
					{error && <p className="mt-4 text-red-600 text-center">{error}</p>}
				</div>
			</div>
		</section>
	);
};

export default WaitingList;
