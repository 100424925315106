import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CONFIG } from '../config';
import { useSections } from '../contexts/SectionsContext';
import { Product } from '../services/api';

interface NavigationProps {
	items: Product[];
}

const Navigation: React.FC<NavigationProps> = ({ items }) => {
	const [isDesktopDropdownOpen, setIsDesktopDropdownOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
	const navigate = useNavigate();
	const { sections } = useSections();
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsDesktopDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleNavigation = (path: string) => {
		setIsMobileMenuOpen(false);
		setIsDesktopDropdownOpen(false);
		setIsMobileDropdownOpen(false);
		navigate(path);
	};

	const handleSectionClick = (sectionId: string) => {
		setIsMobileMenuOpen(false);
		const element = document.getElementById(sectionId);
		element?.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<header className="absolute inset-x-0 top-0 z-50">
			<nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
				<div className="flex lg:flex-1 items-center">
					<Link to="/" className="-m-1.5 p-1.5">
						<img className="h-16 w-auto sm:h-20 md:h-24" src={`${CONFIG.MEDIA_URL}/static/images/logo-white-blk.png`} alt="TalentShake" />
					</Link>
				</div>
				<div className="flex lg:hidden">
					<button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
						<span className="sr-only">Open main menu</span>
						<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
							<path strokeLinecap="round" strokeLinejoin="round" d="M3 12h18M3 6h18M3 18h18" />
						</svg>
					</button>
				</div>
				<div className="hidden lg:flex lg:gap-x-12">
					<div className="relative" ref={dropdownRef}>
						<button onClick={() => setIsDesktopDropdownOpen(!isDesktopDropdownOpen)} className="text-sm font-semibold leading-6 text-gray-900" id="products-dropdown">
							Products
						</button>
						{isDesktopDropdownOpen && (
							<div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" id="dropdown-menu">
								{items.map((product) => (
									<button key={product.slug} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleNavigation(product.slug === 'general' ? '/' : `/${product.slug}`)}>
										{product.display_name}
									</button>
								))}
							</div>
						)}
					</div>
					{sections
						.filter((section) => section.label.toLowerCase() !== 'products') // Filter out the "Products" section
						.map((section) => (
							<button key={section.id} className="text-sm font-semibold leading-6 text-gray-900 hover:text-pink-500" onClick={() => handleSectionClick(section.id)}>
								{section.label}
							</button>
						))}
				</div>
				<div className="hidden lg:flex lg:flex-1 lg:justify-end">
					<a href="https://hr.admin.talentshake.io/login/" className="hidden text-sm font-semibold leading-6 text-gray-900">
						Log in <span aria-hidden="true">→</span>
					</a>
				</div>
			</nav>
			{isMobileMenuOpen && (
				<div className="lg:hidden">
					<div className="fixed inset-0 z-50" />
					<div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
						<div className="flex items-center justify-between">
							<Link to="/" className="-m-1.5 p-1.5" onClick={() => setIsMobileMenuOpen(false)}>
								<img className="h-8 w-auto" src={`${CONFIG.STATIC_URL}/static/images/logo-white-blk.png`} alt="TalentShake" />
							</Link>
							<button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setIsMobileMenuOpen(false)}>
								<span className="sr-only">Close menu</span>
								<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
									<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
						</div>
						<div className="mt-6 flow-root">
							<div className="-my-6 divide-y divide-gray-500/10">
								<div className="space-y-2 py-6">
									<div className="relative -mx-3">
										<button onClick={() => setIsMobileDropdownOpen(!isMobileDropdownOpen)} className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
											Products
											<svg className={`h-5 w-5 flex-none ${isMobileDropdownOpen ? 'rotate-180' : ''}`} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
												<path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
											</svg>
										</button>
										{isMobileDropdownOpen && (
											<div className="mt-2 space-y-2">
												{items.map((product) => (
													<button key={product.slug} className="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => handleNavigation(product.slug === 'general' ? '/' : `/${product.slug}`)}>
														{product.display_name}
													</button>
												))}
											</div>
										)}
									</div>
									{sections
										.filter((section) => section.label.toLowerCase() !== 'products') // Filter out the "Products" section
										.map((section) => (
											<a
												key={section.id}
												href={`#${section.id}`}
												className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
												onClick={(e) => {
													e.preventDefault();
													handleSectionClick(section.id);
												}}
											>
												{section.label}
											</a>
										))}
								</div>
								<div className="py-6 hidden">
									<a href="https://hr.admin.talentshake.io/login/" className="hidden -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => setIsMobileMenuOpen(false)}>
										Log in
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</header>
	);
};

export default Navigation;
