import React, { useEffect, useState } from 'react';
import { api } from '../services/api';

const SitemapXml: React.FC = () => {
	const [content, setContent] = useState('');

	useEffect(() => {
		api.getSitemapXml().then(setContent);
	}, []);

	return <pre>{content}</pre>;
};

export default SitemapXml;
