import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useAnalytics } from '../hooks/useAnalytics';
import { api, Product, ProductLanding } from '../services/api';
import BrandPartners from './BrandPartners';
import FAQ from './FAQ';
import Features from './Features';
import Hero from './Hero';
import Pricing from './Pricing';
import ProductList from './ProductList';
import Team from './Team';
import Testimonials from './Testimonials';
import VideoEmbed from './VideoEmbed';
import WaitingList from './WaitingList';

const ProductLandingPage: React.FC = () => {
	const { slug } = useParams<{ slug: string }>();
	const [landingData, setLandingData] = useState<ProductLanding | null>(null);
	const [allProducts, setAllProducts] = useState<Product[]>([]);
	const [error, setError] = useState<string | null>(null);
	const { trackEvent } = useAnalytics();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await api.getProductLanding(slug || 'general');
				setLandingData(data);
				trackEvent('Product Landing', 'Loaded', slug);

				if (slug === 'general' || !slug) {
					const products = await api.getAllProducts();
					setAllProducts(products);
				}
			} catch (err) {
				console.error('Error fetching data:', err);
				setError('Failed to load data');
			}
		};
		fetchData();
	}, [slug, trackEvent]);

	if (error) return <div>Error: {error}</div>;
	if (!landingData) return <div>Loading...</div>;

	console.log('Landing Data:', landingData);
	console.log('All Products:', allProducts);

	const features = landingData.landing?.features || [];
	const isGeneralPage = landingData.product?.slug?.toLowerCase() === 'general' || slug === 'general' || !slug;
	const productsToDisplay = isGeneralPage ? allProducts : landingData.product ? [landingData.product] : [];

	console.log('Is General Page:', isGeneralPage);
	console.log('Products to Display:', productsToDisplay);

	return (
		<>
			<Helmet>
				<title>{landingData?.landing?.meta_title || landingData?.product.name}</title>
				<meta name="description" content={landingData?.landing?.meta_description || ''} />
				<meta name="keywords" content={landingData?.landing?.meta_keywords || ''} />

				{/* Open Graph tags */}
				<meta property="og:type" content="website" />
				<meta property="og:title" content={landingData?.landing?.og_title || landingData?.product.name || ''} />
				<meta property="og:description" content={landingData?.landing?.og_description || landingData?.landing?.meta_description || ''} />
				<meta property="og:url" content={`https://talentshake.io/${slug}/`} />
				{landingData?.landing?.og_image && <meta property="og:image" content={landingData.landing.og_image} />}

				{/* Twitter Card tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={landingData?.landing?.og_title || landingData?.product.name || ''} />
				<meta name="twitter:description" content={landingData?.landing?.og_description || landingData?.landing?.meta_description || ''} />
				{landingData?.landing?.og_image && <meta name="twitter:image" content={landingData.landing.og_image} />}

				<link rel="canonical" href={landingData?.landing?.canonical_url || ''} />
				<meta name="language" content={landingData?.landing?.language || ''} />

				{landingData?.jsonld && <script type="application/ld+json">{JSON.stringify(landingData.jsonld)}</script>}
			</Helmet>
			<div className="bg-white">
				<Hero title={landingData.landing?.hero_title || ''} description={landingData.landing?.hero_description || ''} productSlug={landingData.product.slug} cta={landingData.landing?.hero_cta || []} />
				{isGeneralPage && productsToDisplay.length > 0 && (
					<>
						<ProductList products={productsToDisplay} />
					</>
				)}
				{features.length > 0 && <Features title={landingData.landing?.features_title || 'Features'} subtitle={landingData.landing?.features_subtitle || ''} description={landingData.landing?.features_subtitle || ''} features={features} cta={landingData.landing?.features_cta || []} />}
				{landingData.landing?.demo_video_url && <VideoEmbed url={landingData.landing.demo_video_url} />}
				{landingData.pricing && <Pricing title={landingData.pricing.title || 'Pricing'} subtitle={landingData.pricing.subtitle || ''} cards={landingData.pricing.cards || []} />}
				{landingData.testimonials && landingData.testimonials.items.length > 0 && <Testimonials title={landingData.testimonials.title} subtitle={landingData.testimonials.subtitle} testimonials={landingData.testimonials.items} />}
				{landingData.partners && landingData.partners.items.length > 0 && <BrandPartners title={landingData.partners.title} subtitle={landingData.partners.subtitle} partners={landingData.partners.items} />}
				{landingData.faq && landingData.faq.length > 0 && (
					<FAQ
						faqs={landingData.faq.map((item) => ({
							question: item.question,
							answer: item.answer,
						}))}
					/>
				)}
				{landingData.team && landingData.team.length > 0 && <Team members={landingData.team} />}
				<WaitingList productName={landingData.product.name} title={landingData.landing?.waiting_list_title || 'Join the Waiting List'} subtitle={landingData.landing?.waiting_list_subtitle || ''} cta={landingData.landing?.waiting_list_cta || []} onSubmit={() => trackEvent('Waiting List', 'Submit', landingData.product.name)} />
			</div>
		</>
	);
};

export default ProductLandingPage;
