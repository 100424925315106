import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api, Article, Product } from '../services/api';

const ArticlesPage: React.FC = () => {
	const [articlesByProduct, setArticlesByProduct] = useState<Record<string, Article[]>>({});
	const [products, setProducts] = useState<Product[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			const fetchedProducts = await api.getAllProducts();
			setProducts(fetchedProducts);

			const articles = await api.getArticles();
			const grouped = articles.reduce((acc, article) => {
				const productName = article.product__name || 'General';
				if (!acc[productName]) acc[productName] = [];
				acc[productName].push(article);
				return acc;
			}, {} as Record<string, Article[]>);
			setArticlesByProduct(grouped);
		};

		fetchData();
	}, []);

	return (
		<div className="container mx-auto px-4 py-8">
			<h1 className="text-3xl font-bold mb-8">Articles by Product</h1>
			{products.map((product) => (
				<div key={product.slug} className="mb-8">
					<h2 className="text-2xl font-semibold mb-4">{product.display_name}</h2>
					<ul className="list-disc pl-5">
						{articlesByProduct[product.name]?.map((article) => (
							<li key={article.slug} className="mb-2">
								<Link to={`/articles/${article.slug}`} className="text-blue-600 hover:underline">
									{article.title}
								</Link>
							</li>
						))}
					</ul>
				</div>
			))}
		</div>
	);
};

export default ArticlesPage;
