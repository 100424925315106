import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { CONFIG } from '../config';
import { api, Article, Author } from '../services/api';
import Breadcrumb from './Breadcrumb';

const ArticleDetail: React.FC = () => {
	const { slug } = useParams<{ slug: string }>();
	const [article, setArticle] = useState<Article | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [relatedArticles, setRelatedArticles] = useState<Article[]>([]);

	useEffect(() => {
		const fetchArticle = async () => {
			if (slug) {
				try {
					const data = await api.getArticle(slug);
					setArticle(data);

					const relatedArticles = await api.getArticles(data.product__name || 'general');
					const filteredRelatedArticles = relatedArticles.filter((article) => article.product__name === data.product__name || article.product__name === 'general');
					// setRelatedArticles(filteredRelatedArticles);
				} catch (err) {
					console.error('Error fetching article:', err);
					setError('Failed to load article');
				}
			}
		};
		fetchArticle();
	}, [slug]);

	const renderAuthors = (authors: Author[]) => {
		if (!authors || authors.length === 0) return null;

		return (
			<div className="mt-4 flex flex-wrap items-center gap-4">
				{authors.map((author, index) => (
					<div key={index} className="flex items-center bg-gray-100 rounded-full p-2">
						<img src={`${CONFIG.STATIC_URL}${author.profile_picture}` || `https://ui-avatars.com/api/?name=${encodeURIComponent(author.name)}&background=random`} alt={author.name} className="w-10 h-10 rounded-full mr-2" />
						<div className="flex flex-col">
							<span className="text-sm font-semibold text-gray-800">{author.name}</span>
							{author.job_title && <span className="text-xs text-gray-600">{author.job_title}</span>}
						</div>
						{author.linkedin_url && (
							<a href={author.linkedin_url} target="_blank" rel="noopener noreferrer" className="ml-2">
								<svg className="w-5 h-5 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
									<path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zM7.65 13.979H5.706V7.723H7.65v6.256zm-.984-7.024c-.614 0-1.011-.435-1.011-.973 0-.549.409-.971 1.036-.971s1.011.422 1.023.971c0 .538-.396.973-1.048.973zm8.084 7.024h-1.944v-3.467c0-.807-.282-1.355-.985-1.355-.537 0-.856.371-.997.728-.052.127-.065.307-.065.486v3.607H8.814v-4.26c0-.781-.025-1.434-.051-1.996h1.689l.089.869h.039c.256-.408.883-1.01 1.932-1.01 1.279 0 2.238.857 2.238 2.699v3.699z" />
								</svg>
							</a>
						)}
					</div>
				))}
			</div>
		);
	};

	if (error) return <div className="mt-20 text-center text-red-500">{error}</div>;
	if (!article) return <div className="mt-20 text-center">Loading...</div>;
	const generateBreadcrumbSchema = () => {
		return {
			'@context': 'https://schema.org',
			'@type': 'BreadcrumbList',
			itemListElement: [
				{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://talentshake.io',
				},
				{
					'@type': 'ListItem',
					position: 2,
					name: 'Articles',
					item: 'https://talentshake.io/articles',
				},
				{
					'@type': 'ListItem',
					position: 3,
					name: article.title,
					item: `https://talentshake.io/articles/${article.slug}`,
				},
			],
		};
	};
	return (
		<>
			<Helmet>
				<title>{article.meta_title || article.title}</title>
				<meta name="description" content={article.meta_description} />
				<meta name="keywords" content={article.keywords} />
				<meta property="og:type" content="article" />
				<meta property="og:title" content={article.og_title || article.title} />
				<meta property="og:description" content={article.og_description || article.meta_description} />
				{article.og_image && <meta property="og:image" content={article.og_image} />}
				<link rel="canonical" href={article.canonical_url} />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={article.og_title || article.title} />
				<meta name="twitter:description" content={article.og_description || article.meta_description} />
				{article.og_image && <meta name="twitter:image" content={article.og_image} />}

				<meta name="language" content={article.language} />
				{article.schema_markup && <script type="application/ld+json">{JSON.stringify(article.schema_markup)}</script>}
				{article?.jsonld && <script type="application/ld+json">{JSON.stringify(article.jsonld)}</script>}
				<script type="application/ld+json">{JSON.stringify(generateBreadcrumbSchema())}</script>
			</Helmet>
			<div className="py-24 sm:py-32 mt-20">
				<article className="mx-auto max-w-3xl px-6 lg:px-8">
					<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{article.title}</h1>
					{renderAuthors(article.authors)}
					<div className="mt-6 prose prose-lg prose-indigo mx-auto text-gray-700" dangerouslySetInnerHTML={{ __html: article.content }} />
					{article.image && <img src={`${CONFIG.STATIC_URL}${article.image}`} alt={article.title} className="mt-8 w-full rounded-xl" loading="lazy" />}
					{article.reference_article ? (
						<div className="mt-8 text-sm text-gray-500">
							<span>Reference: </span>
							<a href={article.reference_article} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
								{article.reference_article}
							</a>
						</div>
					) : (
						<div></div>
					)}
				</article>
				{relatedArticles.length > 0 && (
					<div className="mt-16 mx-auto max-w-3xl px-6 lg:px-8">
						<h2 className="text-2xl font-bold mb-4">Related Articles</h2>
						<ul className="space-y-2">
							{relatedArticles.map((relatedArticle) => (
								<li key={relatedArticle.slug}>
									<Link to={`/articles/${relatedArticle.slug}`} className="text-blue-600 hover:underline">
										{relatedArticle.title}
									</Link>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
			<div className="py-24 sm:py-32 mt-20">
				<div className="mx-auto max-w-3xl px-6 lg:px-8">
					<Breadcrumb
						items={[
							{ label: 'Home', url: '/' },
							{ label: 'Articles', url: '/articles' },
							{ label: article.title, url: `/articles/${article.slug}` },
						]}
					/>
					<article className="mx-auto max-w-3xl px-6 lg:px-8">{/* ... existing article content ... */}</article>
				</div>
			</div>
		</>
	);
};

export default ArticleDetail;
