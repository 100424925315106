import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CONFIG } from '../config';
import { api, Article } from '../services/api';

interface GroupedArticles {
	[key: string]: Article[];
}

interface FooterProps {
	productName: string;
}

const Footer: React.FC<FooterProps> = ({ productName }) => {
	const [articles, setArticles] = useState<GroupedArticles>({});
	const [currentProduct, setCurrentProduct] = useState<string>('general');

	const fetchArticles = useCallback(async () => {
		try {
			const product = productName || 'general';
			setCurrentProduct(product);
			const fetchedArticles = await api.getArticles(product);

			const grouped = fetchedArticles.reduce((acc: GroupedArticles, article: Article) => {
				const category = article.category || 'ARTICLES';
				if (!acc[category]) {
					acc[category] = [];
				}
				if (article.product__name === product || article.product__name === 'general' || article.product__name === null) {
					acc[category].push(article);
				}
				return acc;
			}, {});

			setArticles(grouped);
		} catch (error) {
			console.error('Error fetching articles:', error);
		}
	}, [productName]);

	useEffect(() => {
		fetchArticles();
	}, [fetchArticles]);

	const handleLinkClick = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const renderArticles = (category: string, categoryArticles: Article[]) => {
		if (categoryArticles.length === 0) return null;

		return (
			<div key={category} className="lg:w-1/3 md:w-1/2 w-full px-4">
				<h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">{category.toUpperCase()}</h2>
				<nav className="list-none mb-10">
					{categoryArticles.map((article, index) => (
						<li key={index}>
							<Link to={article.url} className="text-gray-600 hover:text-gray-800" onClick={handleLinkClick}>
								{article.title}
							</Link>
						</li>
					))}
				</nav>
			</div>
		);
	};

	return (
		<footer className="text-gray-600 body-font">
			<div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
				<div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
					<a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900" href="/">
						<img src={`${CONFIG.MEDIA_URL}/static/images/logo-round-blk.png`} alt="TalentShake Logo" className="w-10 h-10" />
						<span className="ml-3 text-xl">TalentShake</span>
					</a>
					<p className="mt-2 text-sm text-gray-500">AI-powered HR solutions</p>
					<div className="mt-4 flex justify-center md:justify-start">
						<a href="https://www.linkedin.com/company/talent-shake/" className="text-gray-500 hover:text-gray-900">
							<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
								<path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
							</svg>
						</a>
					</div>
				</div>
				<div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">{Object.entries(articles).map(([category, categoryArticles]) => renderArticles(category, categoryArticles))}</div>
			</div>
			<div className="bg-gray-100">
				<div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
					<p className="text-gray-500 text-sm text-center sm:text-left">
						© 2023 TalentShake —
						<a href="https://twitter.com/talentshake" rel="noopener noreferrer" className="text-gray-600 ml-1" target="_blank">
							@talentshake
						</a>
					</p>
					<span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
						<a href="https://twitter.com/talentshake" className="ml-3 text-gray-500 hover:text-gray-900">
							<svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
								<path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
							</svg>
						</a>
					</span>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
