import React from 'react';
import { CONFIG } from '../config';

interface TestimonialProps {
	title: string;
	subtitle: string;
	testimonials: Array<{
		name: string;
		position: string;
		company: string;
		quote: string;
		photo: string | null;
	}>;
}

const Testimonials: React.FC<TestimonialProps> = ({ title, subtitle, testimonials }) => {
	return (
		<section className="bg-gray-100 py-16">
			<div className="container mx-auto px-4">
				<h2 className="text-3xl font-bold text-center mb-2">{title}</h2>
				<p className="text-xl text-center text-gray-600 mb-8">{subtitle}</p>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
					{testimonials.map((testimonial, index) => (
						<div key={index} className="bg-white p-6 rounded-lg shadow-md">
							<p className="text-gray-600 italic mb-4">"{testimonial.quote}"</p>
							<div className="flex items-center">
								{testimonial.photo ? (
									<img src={`${CONFIG.STATIC_URL}${testimonial.photo}`} alt={testimonial.name} className="w-12 h-12 rounded-full mr-4 object-cover" />
								) : (
									<div className="w-12 h-12 bg-gray-200 rounded-full mr-4 flex items-center justify-center">
										<span className="text-gray-500 font-bold text-xl">{testimonial.name.charAt(0)}</span>
									</div>
								)}
								<div>
									<p className="font-semibold">{testimonial.name}</p>
									<p className="text-sm text-gray-500">
										{testimonial.position}, {testimonial.company}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
