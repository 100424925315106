// src/hooks/useAnalytics.ts
import { useCallback, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { ConsentContext } from '../contexts/ConsentContext';

declare global {
	interface Window {
		clarity: (command: string, ...args: any[]) => void;
	}
}

const GOOGLE_ANALYTICS_ID = 'G-3NRB1D7PDP';
const CLARITY_PROJECT_ID = 'm6bbqsfiqx';

export const useAnalytics = () => {
	const { cookiesAccepted } = useContext(ConsentContext);
	const [isClarityReady, setIsClarityReady] = useState(false);
	const [isGAInitialized, setIsGAInitialized] = useState(false);

	const initializeAnalytics = useCallback(() => {
		if (cookiesAccepted && !isGAInitialized) {
			try {
				// Initialize Google Analytics
				ReactGA.initialize(GOOGLE_ANALYTICS_ID);
				setIsGAInitialized(true);
			} catch (error) {
				console.error('Error initializing Google Analytics:', error);
			}
		}
	}, [cookiesAccepted, isGAInitialized]);

	useEffect(() => {
		if (cookiesAccepted) {
			initializeAnalytics();

			// Check if the Clarity script is already added
			if (!document.querySelector(`script[src="https://www.clarity.ms/tag/${CLARITY_PROJECT_ID}"]`)) {
				try {
					// Initialize Microsoft Clarity
					const script = document.createElement('script');
					script.src = `https://www.clarity.ms/tag/${CLARITY_PROJECT_ID}`;
					script.async = true;
					script.onload = () => {
						setIsClarityReady(true);
					};
					script.onerror = (e) => {
						console.error('Error loading Clarity script:', e);
					};
					document.head.appendChild(script);
				} catch (error) {
					console.error('Error appending Clarity script:', error);
				}
			} else {
				setIsClarityReady(true);
			}
		}
	}, [cookiesAccepted, initializeAnalytics]);

	const trackPageView = useCallback(
		(path: string) => {
			if (cookiesAccepted && isGAInitialized) {
				ReactGA.send({ hitType: 'pageview', page: path });
			}
			if (cookiesAccepted && isClarityReady && typeof window !== 'undefined' && window.clarity) {
				window.clarity('set', 'page_view', path);
			}
		},
		[cookiesAccepted, isGAInitialized, isClarityReady]
	);

	const trackEvent = useCallback(
		(category: string, action: string, label?: string, value?: number) => {
			if (cookiesAccepted && isGAInitialized) {
				ReactGA.event({ category, action, label, value });
			}
			if (cookiesAccepted && isClarityReady && typeof window !== 'undefined' && window.clarity) {
				window.clarity('event', action, { category, label, value });
			}
		},
		[cookiesAccepted, isGAInitialized, isClarityReady]
	);

	return { trackPageView, trackEvent };
};
